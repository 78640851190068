import {Button, Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {BpsCounterImbalance, BpsPriceParams} from '../../api/generated'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {EditCounterImbalanceDialog} from '../EditCounterImbalanceDialog'
import {EditBpsPriceParamsDialog} from '../EditBpsPriceParamsDialog'
import {BpsRemoteControlDialog} from '../BpsRemoteControlDialog'
import {getCounterImbalanceForBps, getPriceParamsForBps} from '../../api/bpsApi'
import {CounterImbalanceTable} from './Operation/CounterImbalanceTable'
import {BpsPriceParamsTable} from './Operation/BpsPriceParamsTable'

export const OperationView: React.FC = () => {
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: userInfo} = useBucketStore('userInfo')

  const [checkBoxEnableCI, setCheckBoxEnableCI] = React.useState<boolean>(true)
  const [checkBoxBpsSupportsPayoff, setCheckBoxBpsSupportsPayoff] = React.useState<boolean>(true)

  const [isEditCounterImbalanceDialogOpen, setIsEditCounterImbalanceDialogOpen] = useState<boolean>(false)
  const [isEditBpsPriceParamsDialogOpen, setIsEditBpsPriceParamsDialogOpen] = useState<boolean>(false)
  const [isBpsRemoteControlDialogOpen, setIsBpsRemoteControlDialogOpen] = useState<boolean>(false)

  const [bpsCounterImbalance, setBpsCounterImbalance] = useState<BpsCounterImbalance>()
  const [bpsPriceParams, setBpsPriceParams] = useState<BpsPriceParams>()

  const {run: runGetCounterImbalanceForBps} = useAsyncMethodWithErrorHandling(getCounterImbalanceForBps)
  const {run: runGetPriceParamsForBps} = useAsyncMethodWithErrorHandling(getPriceParamsForBps)

  const handleCloseEditCounterImbalanceDialog = () => {
    setIsEditCounterImbalanceDialogOpen(false)
  }

  const handleCloseEditBpsPriceParamsDialog = () => {
    setIsEditBpsPriceParamsDialogOpen(false)
  }

  const handleCloseBpsRemoteControlDialog = () => {
    setIsBpsRemoteControlDialogOpen(false)
  }

  const fetchBpsCounterImbalance = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetCounterImbalanceForBps({bpsId: selectedBpsId})).data

      setBpsCounterImbalance(result)
      setCheckBoxEnableCI(result?.counter_imbalance_enabled == undefined ? false : result.counter_imbalance_enabled)
    }
  }, [runGetCounterImbalanceForBps, selectedBpsId])

  const fetchBpsPriceParams = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetPriceParamsForBps({bpsId: selectedBpsId})).data

      setBpsPriceParams(result)
      setCheckBoxBpsSupportsPayoff(result?.supports_payoff == undefined ? false : result.supports_payoff)
    }
  }, [runGetPriceParamsForBps, selectedBpsId])

  const handleCounterImbalanceUpdate = () => {
    setIsEditCounterImbalanceDialogOpen(false)
    fetchBpsCounterImbalance()
  }

  const handleBpsPriceParamsUpdate = () => {
    setIsEditBpsPriceParamsDialogOpen(false)
    fetchBpsPriceParams()
  }

  useEffect(() => {
    fetchBpsCounterImbalance()
    fetchBpsPriceParams()
  }, [fetchBpsCounterImbalance, fetchBpsPriceParams])

  return (
    <>
      <CounterImbalanceTable
        setIsEditCounterImbalanceDialogOpen={setIsEditCounterImbalanceDialogOpen}
        bpsCounterImbalance={bpsCounterImbalance}
        checkBoxEnableCI={checkBoxEnableCI}
      />

      <BpsPriceParamsTable
        setIsEditBpsPriceParamsDialogOpen={setIsEditBpsPriceParamsDialogOpen}
        bpsPriceParams={bpsPriceParams}
        checkBoxBpsSupportsPayoff={checkBoxBpsSupportsPayoff}
      />

      {(userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('GROUP_OPERATOR')) && (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          marginTop={4}
          marginBottom={4}
          spacing={1}
          sx={{gap: '12px', display: 'flex'}}
        >
          <Button onClick={() => setIsBpsRemoteControlDialogOpen(true)}>Administrátor</Button>
        </Stack>
      )}

      {isEditCounterImbalanceDialogOpen && (
        <EditCounterImbalanceDialog
          open={isEditCounterImbalanceDialogOpen}
          onClose={handleCloseEditCounterImbalanceDialog}
          onUpdate={handleCounterImbalanceUpdate}
          counterImbalance={bpsCounterImbalance}
        />
      )}

      {isEditBpsPriceParamsDialogOpen && (
        <EditBpsPriceParamsDialog
          open={isEditBpsPriceParamsDialogOpen}
          onClose={handleCloseEditBpsPriceParamsDialog}
          onUpdate={handleBpsPriceParamsUpdate}
          bpsPriceParams={bpsPriceParams}
        />
      )}

      {isBpsRemoteControlDialogOpen && (
        <BpsRemoteControlDialog open={isBpsRemoteControlDialogOpen} onClose={handleCloseBpsRemoteControlDialog} />
      )}
    </>
  )
}
