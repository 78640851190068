import {
  Badge,
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LoginIcon from '@mui/icons-material/Login'
import React, {useEffect, useState} from 'react'
import {
  getGroupNominations,
  getGroupNominationsMinimumAndMaximum,
  getGroupNominationsPeriod,
} from '../../api/nominationsApi'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {calculatePeriodFromIsoDate, formatTimeRange} from '../../utils/format'
import {
  BpsCalculatedNominationValueColorEnum,
  BpsNominationsMinimumAndMaximum,
  NominationControllerApiGetGroupNominationsMinimumAndMaximumRequest,
  BpsGroupNominationsPeriod,
} from '../../api/generated'
import {CalendarPicker, PickersDay} from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import {ArrowBack, Upload} from '@mui/icons-material'
import {isViewOfPeriodDetailAllowed} from '../../utils/validateUserRolesAndBps'
import {CalendarForGroupNominationsDialog} from '../CalendarForGroupNominationsDialog'
import {SendGroupNominationsStatusDialog} from '../SendGroupNominationsStatusDialog'
import {SendPlanToAggregatorStatusDialog} from '../SendPlanToAggregatorStatusDialog'
import {NominationOverrideDialog} from '../NominationOverrideDialog'

const StyledTableRow = styled(TableRow)(({color}) => {
  let bgColor = '#92c93778'
  if (color == BpsCalculatedNominationValueColorEnum.Orange) {
    bgColor = '#ff8c5499'
  } else if (color == BpsCalculatedNominationValueColorEnum.Red) {
    bgColor = '#ff41418a'
  }

  return {
    backgroundColor: bgColor,
  }
})

const StyledTableHeadRow = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const CustomCalendarPicker = styled(CalendarPicker)(() => ({
  width: '100%',
  margin: 0,

  '.MuiDayPicker-header': {
    justifyContent: 'space-around',
  },

  '.MuiDayPicker-weekContainer': {
    justifyContent: 'space-around',
  },
}))

const StyledPickersDay = styled(PickersDay)(({color}) => {
  let bgColor = '#fff'
  if (color == BpsCalculatedNominationValueColorEnum.Orange) {
    bgColor = '#ff8c5499'
  } else if (color == BpsCalculatedNominationValueColorEnum.Red) {
    bgColor = '#ff41418a'
  } else if (color == BpsCalculatedNominationValueColorEnum.Green) {
    bgColor = '#92c93778'
  }

  return {
    backgroundColor: bgColor,
  }
})

export const StyledIconButton = styled(IconButton)(() => ({
  backgroundColor: 'white',
  color: '#666666',
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: 'white',
    color: '#92C937',
  },
}))

type GroupNominationsTableProps = {
  openNominationTableForBpsAndPeriod: () => void
}

const BADGE = '🔴'

export const GroupNominationsTable: React.FC<GroupNominationsTableProps> = ({openNominationTableForBpsAndPeriod}) => {
  const {data: groupNominations, setData: setGroupNominations} = useBucketStore('groupNominations')
  const {data: userInfo} = useBucketStore('userInfo')
  const {data: newSelectedDate, setData: setNewSelectedDate} = useBucketStore('selectedDate')
  const {setData: setSelectedBpsId} = useBucketStore('selectedBpsId')

  const [open, setOpen] = useState<number | undefined>(-1)

  const [isSendProductionPlanDialogOpen, openSendProductionPlanDialogOpen] = useState<boolean>(false)
  const [isNominationOverrideDialogOpen, setNominationOverrideDialogOpen] = useState<boolean>(false)
  const [statusSendingGroupNominationsDialog, setStatusSendingGroupNominationsDialog] = useState<boolean>(false)
  const [statusMessageSendingGroupNominations, setStatusMessageSendingGroupNominations] = useState<string>('')
  const [statusSendingProductionPlanToAggregatorDialog, setStatusSendingProductionPlanToAggregatorDialog] =
    useState<boolean>(false)
  const [statusMessageSendingProductionPlanToAggregator, setStatusMessageSendingProductionPlanToAggregator] =
    useState<string>('')

  const {run: runGetGroupNominations} = useAsyncMethodWithErrorHandling(getGroupNominations)
  const {run: runGetGroupNominationsMinimumMaximum} = useAsyncMethodWithErrorHandling(
    getGroupNominationsMinimumAndMaximum,
  )
  const {run: runGetGroupNominationsPeriod} = useAsyncMethodWithErrorHandling(getGroupNominationsPeriod)

  const [groupNominationsPeriod, setGroupNominationsPeriod] = useState<BpsGroupNominationsPeriod[]>([])
  const [groupNominationsMinimumMaximum, setGroupNominationsMinimumMaximum] = useState<
    BpsNominationsMinimumAndMaximum[]
  >([])
  const [isTableOpened, setIsTableOpened] = useState<boolean>(false)

  const [dateFrom, setDateFrom] = useState<string>(dayjs().date(1).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState<string>(dayjs().date(dayjs().daysInMonth()).format('YYYY-MM-DD'))

  const [filteredMinMaxNominations, setFilteredMinMaxNominations] = useState<BpsNominationsMinimumAndMaximum>()

  const [minMaxNominationsLoaded, setMinMaxNominationsLoaded] = useState<boolean>(false)

  const [currentSelectedMonth, setCurrentSelectedMonth] = useState<string>(dayjs(newSelectedDate).format('MM'))

  const fetchGroupNominations = React.useCallback(
    async (date: string) => {
      const normalizedDate = date.substring(0, 10)
      const result = (await runGetGroupNominations({date: normalizedDate})).data

      setGroupNominations(result?.calculated ?? [])
    },
    [runGetGroupNominations, setGroupNominations],
  )

  const fetchGroupNominationsMinimumMaximum = React.useCallback(async () => {
    const result = (await runGetGroupNominationsMinimumMaximum({dateFrom, dateTo})).data

    setGroupNominationsMinimumMaximum(result?.nominations ?? [])
  }, [runGetGroupNominationsMinimumMaximum, setGroupNominationsMinimumMaximum, dateFrom, dateTo])

  const fetchGroupNominationsMinimumMaximumWithParams = React.useCallback(
    async (dateFromParam: string, dateToParam: string) => {
      const dateInterval: NominationControllerApiGetGroupNominationsMinimumAndMaximumRequest = {
        dateFrom: dateFromParam,
        dateTo: dateToParam,
      }
      const result = (await runGetGroupNominationsMinimumMaximum(dateInterval)).data

      setGroupNominationsMinimumMaximum(result?.nominations ?? [])
    },
    [runGetGroupNominationsMinimumMaximum, setGroupNominationsMinimumMaximum],
  )

  const fetchGroupNominationsPeriod = React.useCallback(
    async (timeFrom: string) => {
      const result = (await runGetGroupNominationsPeriod({timeFrom})).data

      setGroupNominationsPeriod(result?.bps_period_nominations ?? [])
    },
    [runGetGroupNominationsPeriod, setGroupNominationsPeriod],
  )

  const handleCloseSendProductionPlanDialog = () => {
    openSendProductionPlanDialogOpen(false)
  }

  const handeFinishSendGroupNominationsDialog = (statusMessage: string) => {
    setStatusMessageSendingGroupNominations(statusMessage)
    setStatusSendingGroupNominationsDialog(true)
  }

  const handeFinishSendProductionPlanToAggregatorDialog = (statusMessage: string) => {
    setStatusMessageSendingProductionPlanToAggregator(statusMessage)
    setStatusSendingProductionPlanToAggregatorDialog(true)
  }

  const handleCloseSendGroupNominationsErrorDialog = () => {
    setStatusSendingGroupNominationsDialog(false)
    setStatusMessageSendingGroupNominations('')
  }

  const handleCloseSendProductionPlanToAggregatorErrorDialog = () => {
    setStatusSendingProductionPlanToAggregatorDialog(false)
    setStatusMessageSendingProductionPlanToAggregator('')
  }

  const handleOpenBpsGroupNominationsPeriod = (index: number, timeFrom: string) => {
    setOpen(index)
    fetchGroupNominationsPeriod(timeFrom)
  }

  const handleCloseBpsGroupNominationsPeriod = () => {
    setOpen(-1)
  }

  const handleCloseNominationDetail = () => {
    setIsTableOpened(false)
    setMinMaxNominationsLoaded(false)
    handleCloseBpsGroupNominationsPeriod()
  }

  const handleGoToTableView = (bpsPeriodId: number) => {
    setSelectedBpsId(bpsPeriodId)
    openNominationTableForBpsAndPeriod()
  }

  const handleCloseNominationOverrideDialog = () => {
    setNominationOverrideDialogOpen(false)
  }

  const getMinMaxNominationForSelectedDate = React.useCallback(() => {
    const minMax = groupNominationsMinimumMaximum.filter(
      (item) => item.date === dayjs(newSelectedDate).format('YYYY-MM-DD'),
    )
    setFilteredMinMaxNominations(minMax[0])
  }, [newSelectedDate, groupNominationsMinimumMaximum])

  useEffect(() => {
    // call fetch only when Detail table is closed
    if (!isTableOpened && !minMaxNominationsLoaded) {
      fetchGroupNominationsMinimumMaximum()
      setMinMaxNominationsLoaded(true) // without this, fetchGroupNominationsMinimumMaximum was being called in a loop while detail table was closed
    }

    // call fetch only when detail table is open for showing day detail after changing the date on DatePicker
    if (isTableOpened) {
      fetchGroupNominations(dayjs(newSelectedDate as unknown as Date).format('YYYY-MM-DD'))
    }

    // to load MIN MAX values when detail is open and DatePicker is set to different month than CustomCalendarPicker
    if (currentSelectedMonth != dayjs(newSelectedDate).format('MM') && isTableOpened) {
      setCurrentSelectedMonth(dayjs(newSelectedDate).format('MM'))
      fetchGroupNominationsMinimumMaximumWithParams(
        dayjs(newSelectedDate).date(1).format('YYYY-MM-DD'),
        dayjs(newSelectedDate).date(dayjs(newSelectedDate).daysInMonth()).format('YYYY-MM-DD'),
      )
    }

    getMinMaxNominationForSelectedDate()
    handleCloseBpsGroupNominationsPeriod()
  }, [
    fetchGroupNominationsMinimumMaximum,
    newSelectedDate,
    fetchGroupNominations,
    isTableOpened,
    minMaxNominationsLoaded,
    getMinMaxNominationForSelectedDate,
    currentSelectedMonth,
    fetchGroupNominationsMinimumMaximumWithParams,
  ])

  return (
    <>
      {(userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('GROUP_OPERATOR')) && (
        <Stack direction="row" alignItems="start" marginTop={3} marginBottom={2} sx={{gap: '12px'}}>
          <Button endIcon={<Upload fontSize="large" />} onClick={() => openSendProductionPlanDialogOpen(true)}>
            Poslať plán výroby
          </Button>
          <Button onClick={() => setNominationOverrideDialogOpen(true)}>Prepísať nominácie</Button>
        </Stack>
      )}
      <CustomCalendarPicker
        onChange={(newValue) => {
          if (newValue) {
            setNewSelectedDate(dayjs(newValue as Date).format('YYYY-MM-DD'))
            setCurrentSelectedMonth(dayjs(newValue as Date).format('MM'))
            setIsTableOpened(true)
          }
        }}
        onMonthChange={(value) => {
          setDateFrom(
            dayjs(value as Date)
              .date(1)
              .format('YYYY-MM-DD'),
          )
          setDateTo(
            dayjs(value as Date)
              .date(dayjs(value as Date).daysInMonth())
              .format('YYYY-MM-DD'),
          )
          setMinMaxNominationsLoaded(false)
        }}
        date={undefined}
        renderDay={(day, selectedDays, pickersDayProps) => {
          const groupNominationMinMax = groupNominationsMinimumMaximum.filter(
            (item) => item.date === dayjs(day as Date).format('YYYY-MM-DD'),
          )

          const color = groupNominationMinMax[0]?.value_color ?? 'red'
          const showBadge = groupNominationMinMax[0]?.show_badge ?? false

          return (
            <Badge
              key={groupNominationMinMax[0]?.date.toString()}
              overlap="circular"
              badgeContent={showBadge ? BADGE : undefined}
            >
              <StyledPickersDay {...pickersDayProps} color={color}>
                <Tooltip
                  title={
                    // using div style for \n separation
                    <div style={{whiteSpace: 'pre-line'}}>
                      {`MIN: ${groupNominationMinMax[0]?.min_value ?? 0} MW \n MAX: ${
                        groupNominationMinMax[0]?.max_value ?? 0
                      } MW`}
                    </div>
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span>{dayjs(day as Date).date()}</span>
                  </div>
                </Tooltip>
              </StyledPickersDay>
            </Badge>
          )
        }}
        views={['day']}
        sx={{display: isTableOpened ? 'none' : 'block'}}
      />

      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        marginTop={0.5}
        sx={{gap: '12px', display: isTableOpened ? 'flex' : 'none'}}
      >
        <Button
          endIcon={<ArrowBack fontSize="large" />}
          onClick={() => handleCloseNominationDetail()}
          color={'primary'}
        >
          Späť do kalendára
        </Button>
      </Stack>

      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="start"
        marginTop={2}
        marginBottom={2}
        sx={{display: isTableOpened ? 'flex' : 'none'}}
      >
        <Box>
          Minimálna hodnota:{' '}
          <Box fontWeight="medium" display="inline">
            {filteredMinMaxNominations?.min_value} MW{' '}
          </Box>
        </Box>
        <Box>
          Maximálna hodnota:{' '}
          <Box fontWeight="medium" display="inline">
            {filteredMinMaxNominations?.max_value} MW{' '}
          </Box>
        </Box>
      </Stack>

      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{gap: '12px', display: isTableOpened ? 'flex' : 'none'}}
      >
        <TableContainer component={Paper}>
          <Table size="small" sx={{padding: '20px 0'}}>
            <TableHead>
              <StyledTableHeadRow>
                <TableCell>#</TableCell>
                <TableCell>Čas</TableCell>
                <TableCell>MW</TableCell>
                <TableCell />
                <TableCell />
              </StyledTableHeadRow>
            </TableHead>

            <TableBody>
              {groupNominations.map((item, index) => (
                <>
                  <StyledTableRow key={index} color={item.value_color}>
                    <TableCell>{calculatePeriodFromIsoDate(item.time_from)}</TableCell>
                    <TableCell>{formatTimeRange(item.time_from, item.time_to)}</TableCell>
                    <TableCell>{item.value}</TableCell>
                    <TableCell>{item.show_badge ? BADGE : ''}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          open == index
                            ? handleCloseBpsGroupNominationsPeriod()
                            : handleOpenBpsGroupNominationsPeriod(index, item.time_from)
                        }
                        // disabled={item.value == undefined}
                        disabled={!isViewOfPeriodDetailAllowed(userInfo)}
                      >
                        {open == index ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                  <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                      <Collapse in={open == index} timeout="auto" unmountOnExit>
                        <TableContainer>
                          <Table size="small" sx={{padding: '20px 0'}}>
                            <TableHead>
                              <TableCell>BPS</TableCell>
                              <TableCell>MW</TableCell>
                              <TableCell />
                              <TableCell />
                            </TableHead>
                            <TableBody>
                              {groupNominationsPeriod.map((bpsPeriod) => (
                                <>
                                  <TableRow>
                                    <TableCell style={{borderBottom: 'none'}}>{bpsPeriod.name}</TableCell>
                                    <TableCell style={{borderBottom: 'none'}}>
                                      {bpsPeriod.value_mw == null ? 'Nezadané' : bpsPeriod.value_mw}
                                    </TableCell>
                                    <TableCell style={{borderBottom: 'none'}}>
                                      {bpsPeriod.show_badge ? BADGE : ''}
                                    </TableCell>
                                    <TableCell style={{borderBottom: 'none'}} align="right">
                                      <StyledIconButton onClick={() => handleGoToTableView(bpsPeriod.id)}>
                                        <LoginIcon />
                                      </StyledIconButton>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          endIcon={<ArrowBack fontSize="large" />}
          onClick={() => setIsTableOpened(false)}
          color={'primary'}
          sx={{display: groupNominations.length > 0 ? 'inline-flex' : 'none'}}
        >
          Späť do kalendára
        </Button>
      </Stack>

      {isSendProductionPlanDialogOpen && (
        <CalendarForGroupNominationsDialog
          open={isSendProductionPlanDialogOpen}
          onCloseSendProductionPlan={handleCloseSendProductionPlanDialog}
          onFinishSendGroupNominations={handeFinishSendGroupNominationsDialog}
          onFinishSendProductionPlanToAggregator={handeFinishSendProductionPlanToAggregatorDialog}
        />
      )}
      {statusSendingGroupNominationsDialog && (
        <SendGroupNominationsStatusDialog
          open={statusSendingGroupNominationsDialog}
          onClose={handleCloseSendGroupNominationsErrorDialog}
          statusMessage={statusMessageSendingGroupNominations}
        />
      )}
      {statusSendingProductionPlanToAggregatorDialog && (
        <SendPlanToAggregatorStatusDialog
          open={statusSendingProductionPlanToAggregatorDialog}
          onClose={handleCloseSendProductionPlanToAggregatorErrorDialog}
          statusMessage={statusMessageSendingProductionPlanToAggregator}
        />
      )}
      {isNominationOverrideDialogOpen && (
        <NominationOverrideDialog open={isNominationOverrideDialogOpen} onClose={handleCloseNominationOverrideDialog} />
      )}
    </>
  )
}
