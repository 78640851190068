import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material'
import dayjs from 'dayjs'
import React, {useState, useEffect, useCallback, useRef} from 'react'
import {BpsParametersExtended, Device, DeviceTypeEnum, NominationDevice, NominationProfile} from '../api/generated'
import {updateProfileV2} from '../api/profilesApi'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../utils/BucketStoreContext'
import {ConfirmUpdateProfileDialog} from './ConfirmUpdateProfileDialog'
import {NominationDialogIntervalSelector} from '../components/NominationDialogIntervalSelector'
import {NominationInterval} from '../components/AddNominationDialog'
import {NominationDialogPeriods} from '../components/NominationDialogPeriods'
import {NominationDialogDevicesTable} from './NominationDialogDevicesTable'
import {getBpsDetailsV2, getDevicesForBps} from '../api/bpsApi'
import {NominationDialogSlider} from '../components/NominationDialogSlider'
import {NominationDialogTechParams} from '../components/NominationDialogTechParams'
import {NominationDialogStatusSelector} from './NominationDialogStatusSelector'
import {NominationIsOutOfRegLimitDialog} from './NominationIsOutOfRegLimitDialog'
import {parseToFixedNumber} from '../utils/common'

type UpdateProfileDialogProps = {
  open: boolean
  onClose: () => void
  fetchProfileNominations: () => void
}

const getDefaultProfileNominationData = (profileId: number): NominationProfile => ({
  time_from: dayjs().startOf('hour').toISOString(),
  time_to: dayjs().endOf('hour').add(1, 'ms').toISOString(),
  value_percentage: 100,
  note: '',
  profile_id: profileId,
  production_status: 2,
})

export const UpdateProfileDialog: React.FC<UpdateProfileDialogProps> = ({open, onClose, fetchProfileNominations}) => {
  const {data: selectedProfile} = useBucketStore('selectedProfile')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: productionStatus} = useBucketStore('productionStatusEnum')

  const [editedProfileNomination, setEditedProfileNomination] = useState<NominationProfile>(() =>
    getDefaultProfileNominationData(selectedProfile?.id ?? -1),
  )

  const [isConfirmUpdateProfileDialogOpen, setIsConfirmUpdateProfileDialogOpen] = useState<boolean>(false)
  const [selectedInterval, setSelectedInterval] = useState(NominationInterval.Hours)
  const [selectedDevices, setSelectedDevices] = useState<number[] | undefined>(undefined)
  const startOfDayTimestamp = dayjs().startOf('day')
  const endOfDayTimestamp = dayjs().endOf('day').add(1, 'ms')
  const [devicesForBps, setDevicesForBps] = useState<undefined | Device[]>(undefined)
  const [bpsParameters, setBpsParameters] = useState<BpsParametersExtended>()
  const [enableSubmit, setEnableSubmit] = useState<boolean>(true)
  const [isNominationIsOutOfRegLimitDialogOpen, setIsNominationIsOutOfRegLimitDialogOpen] = useState<boolean>(false)

  const {run: runUpdateProfileV2} = useAsyncMethodWithErrorHandling(updateProfileV2)
  const {run: runGetDevicesForBps} = useAsyncMethodWithErrorHandling(getDevicesForBps)
  const {run: runGetBpsDetailsV2} = useAsyncMethodWithErrorHandling(getBpsDetailsV2)

  const bpsInstMw = useRef(-1)

  const fetchBpsDetails = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetBpsDetailsV2({id: selectedBpsId})).data
      setBpsParameters(result?.parameters)
    }
  }, [runGetBpsDetailsV2, selectedBpsId])

  useEffect(() => {
    fetchBpsDetails()
  }, [fetchBpsDetails])

  const fetchDevicesForBps = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetDevicesForBps({id: selectedBpsId})).data
      setDevicesForBps(
        result?.devices.filter((device) => device.type === DeviceTypeEnum.Chp).sort((a, b) => (a.id > b.id ? 1 : -1)),
      )
    }
  }, [runGetDevicesForBps, selectedBpsId])

  useEffect(() => {
    fetchDevicesForBps()
  }, [fetchDevicesForBps])

  useEffect(() => {
    if (open) {
      setEditedProfileNomination(getDefaultProfileNominationData(selectedProfile?.id ?? -1))
    }
  }, [open, selectedProfile])

  const editProperties = (changes: Partial<NominationProfile>) => {
    setEditedProfileNomination((prev) => ({...prev, ...changes}))
  }

  const getSelectedDevicesInstMw = useCallback(
    (selectedDevicesToUse: number[]) =>
      devicesForBps
        ?.filter((device) => selectedDevicesToUse?.find((item) => item === device.id))
        .reduce(function (prev, current) {
          return prev + current.p_inst_mw
        }, 0),
    [devicesForBps],
  )

  const createEmptyNominationDevice = (nominationId: number): NominationDevice => {
    return {
      device_id: nominationId.toString(),
      time_from: '', // will be set on BEExistujúci profil nominácií:
      time_to: '', // will be set on BE
      p_term_mw: 0,
      p_reg_min_mw: 0,
      p_reg_max_mw: 0,
      in_nomination: false,
    }
  }

  const calculatePTermMw = (deviceCharPInstMw: number) => {
    const sumPInstMwSelectedDevices = selectedDevices ? getSelectedDevicesInstMw(selectedDevices) ?? 0 : 0

    const firstPart =
      sumPInstMwSelectedDevices - ((editedProfileNomination.value_percentage ?? 0) * bpsInstMw.current) / 100
    const secondPart = deviceCharPInstMw / sumPInstMwSelectedDevices

    return (deviceCharPInstMw - firstPart * secondPart).toFixed(3)
  }

  const createNominationDevice = (
    nominationId: number,
    pRegMinMw: number,
    pRegMaxMw: number,
    pInstMw: number,
  ): NominationDevice => ({
    device_id: nominationId.toString(),
    time_from: '',
    time_to: '',
    p_term_mw: Number(calculatePTermMw(pInstMw)),
    p_reg_min_mw: pRegMinMw,
    p_reg_max_mw: pRegMaxMw,
    in_nomination: true,
  })

  const getNominationsForDevices = (): NominationDevice[] | [] => {
    const nominationsDevices: NominationDevice[] = []

    devicesForBps?.forEach((device) => {
      const newNomination =
        selectedDevices?.indexOf(device.id) !== -1
          ? createNominationDevice(device.id, device.p_reg_min_mw, device.p_reg_max_mw, device.p_inst_mw)
          : createEmptyNominationDevice(device.id)

      nominationsDevices.push(newNomination)
    })

    return nominationsDevices
  }

  const updateSliderValue = useCallback(
    (selectedDevicesNewValue: number[]) => {
      const selectedDevicesInstMw = getSelectedDevicesInstMw(selectedDevicesNewValue)

      if (selectedDevicesInstMw !== undefined && bpsInstMw.current !== -1) {
        const newValue = (selectedDevicesInstMw / bpsInstMw.current) * 100
        editProperties({value_percentage: Number(newValue.toFixed(0))})
      }
    },
    [getSelectedDevicesInstMw],
  )

  useEffect(() => {
    if (devicesForBps?.length && selectedDevices === undefined) {
      const newValue = devicesForBps.filter((item) => item.in_service).map((item) => item.id)
      setSelectedDevices(newValue)

      bpsInstMw.current =
        devicesForBps?.reduce(function (prev, current) {
          return prev + current.p_inst_mw
        }, 0) ?? 0

      updateSliderValue(newValue)
    }
  }, [devicesForBps, selectedDevices, updateSliderValue])

  const handleUpdate = async (isOutOfRegLimit: boolean) => {
    if (isOutOfRegLimit) {
      editedProfileNomination.production_status = 3
    }

    if (selectedBpsId && selectedProfile?.id) {
      await runUpdateProfileV2({
        updateProfileWithDevicesRequest: {
          bps_id: selectedBpsId,
          profile_id: selectedProfile.id,
          time_from: dayjs(editedProfileNomination.time_from).format('HH:mm:ss'),
          time_to: dayjs(editedProfileNomination.time_to).format('HH:mm:ss'),
          value_percentage: editedProfileNomination.value_percentage,
          note: editedProfileNomination.note,
          nomination_devices: getNominationsForDevices(),
          production_status: editedProfileNomination.production_status,
        },
      })
    }
    setIsConfirmUpdateProfileDialogOpen(false)
    onClose()
    fetchProfileNominations()
  }

  const handleConfirmUpdate = async () => {
    const nomValue = Number(
      (editedProfileNomination.value_percentage && bpsParameters
        ? bpsInstMw.current * (editedProfileNomination.value_percentage / 100) - bpsParameters.tvs_mw
        : 0
      ).toFixed(3),
    )

    const maxRegValue =
      devicesForBps
        ?.filter((device) => selectedDevices?.includes(device.id))
        ?.reduce(function (prev, current) {
          return prev + current?.p_reg_max_mw
        }, 0) ?? 0

    const minRegValue =
      devicesForBps
        ?.filter((device) => selectedDevices?.includes(device.id))
        ?.reduce(function (prev, current) {
          return prev + current?.p_reg_min_mw
        }, 0) ?? 0

    if (
      (parseToFixedNumber(nomValue + (bpsParameters?.tvs_mw ?? 0), 3) > maxRegValue ||
        parseToFixedNumber(nomValue + (bpsParameters?.tvs_mw ?? 0), 3) < minRegValue) &&
      editedProfileNomination.production_status != 3
    ) {
      setIsNominationIsOutOfRegLimitDialogOpen(true)
      return
    }

    handleUpdate(false)
  }

  const valueIsValid = () => {
    return editedProfileNomination.value_percentage != undefined && editedProfileNomination.value_percentage >= 0
  }

  const isSelected = (id: number | undefined) => {
    return selectedDevices?.find((item) => item === id) !== undefined
  }

  const calculateMaxValueForSlider = () => {
    const maxDosForSelectedDevices = devicesForBps
      ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
      .reduce(function (prev, current) {
        return prev + current?.p_dos_max_mw
      }, 0)

    const maxDosForAllDevices = devicesForBps?.reduce(function (prev, current) {
      return prev + current?.p_dos_max_mw
    }, 0)

    return Math.floor(
      maxDosForSelectedDevices && maxDosForAllDevices ? (maxDosForSelectedDevices * 100) / maxDosForAllDevices : 0,
    )
  }

  const calculateMinValueForSlider = () => {
    const minDosForSelectedDevices = devicesForBps
      ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
      .reduce(function (prev, current) {
        return prev + current?.p_dos_min_mw
      }, 0)

    const maxDosForAllDevices = devicesForBps?.reduce(function (prev, current) {
      return prev + current?.p_dos_max_mw
    }, 0)

    return Math.floor(
      minDosForSelectedDevices && maxDosForAllDevices ? (minDosForSelectedDevices * 100) / maxDosForAllDevices : 0,
    )
  }

  const handleClickCheckBox = (id: number | undefined, checked: boolean) => {
    if (id === undefined) {
      return
    }

    if (checked) {
      // add to array of selected
      const filtered = selectedDevices?.filter((item) => item !== id)
      const updated = filtered ?? []
      updated.push(id)
      setSelectedDevices(updated)
      updateSliderValue(updated)
    } else {
      // remove from array of selected
      const filtered = selectedDevices?.filter((item) => item !== id)
      setSelectedDevices(filtered)
      filtered ? updateSliderValue(filtered) : updateSliderValue([])
    }
  }

  const handleAllDaySelected = () => {
    setSelectedInterval(NominationInterval.AllDay)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const handleHoursIntervalSelected = () => {
    setSelectedInterval(NominationInterval.Hours)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const handleFifteenMinutesIntervalSelected = () => {
    setSelectedInterval(NominationInterval.FifteenMinutes)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const handleValueInSliderChange = (valid: boolean) => {
    setEnableSubmit(valid)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Upraviť profil: {selectedProfile?.name}</DialogTitle>
        <DialogContent>
          <Stack>
            <NominationDialogIntervalSelector
              handleAllDaySelected={handleAllDaySelected}
              handleFifteenMinutesIntervalSelected={handleFifteenMinutesIntervalSelected}
              handleHoursIntervalSelected={handleHoursIntervalSelected}
              selectedInterval={selectedInterval}
            />

            <NominationDialogPeriods
              editedNomination={editedProfileNomination}
              onEditProperties={editProperties}
              selectedInterval={selectedInterval}
              isProfileEdit={true}
            />

            <NominationDialogStatusSelector
              editedNomination={editedProfileNomination}
              productionStatusEnum={productionStatus}
              onValueChange={(value: number) => editProperties({production_status: value})}
            />

            <NominationDialogDevicesTable
              handleClickCheckBox={handleClickCheckBox}
              isSelected={isSelected}
              devicesForBps={devicesForBps}
            />

            <NominationDialogSlider
              editedNomination={editedProfileNomination}
              minValueForSlider={calculateMinValueForSlider()}
              maxValueForSlider={calculateMaxValueForSlider()}
              onEditProperties={editProperties}
              onValueChanged={handleValueInSliderChange}
            />

            <NominationDialogTechParams
              bpsInstMw={bpsInstMw.current}
              editedNomination={editedProfileNomination}
              onEditProperties={editProperties}
              tvsMw={bpsParameters?.tvs_mw}
              ovsMw={bpsParameters?.ovs_mw}
              devicesForBps={devicesForBps ?? []}
              selectedDevices={selectedDevices ?? []}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button
            onClick={() => setIsConfirmUpdateProfileDialogOpen(true)}
            variant="contained"
            disabled={!valueIsValid() || !enableSubmit}
          >
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmUpdateProfileDialog
        open={isConfirmUpdateProfileDialogOpen}
        onClose={() => setIsConfirmUpdateProfileDialogOpen(false)}
        profileNomination={editedProfileNomination}
        onConfirm={handleConfirmUpdate}
        nominationDevices={getNominationsForDevices()}
        pInstMw={bpsParameters?.p_inst_mw}
        tvsMw={bpsParameters?.tvs_mw}
        allDevices={devicesForBps}
      />

      <NominationIsOutOfRegLimitDialog
        open={isNominationIsOutOfRegLimitDialogOpen}
        onClose={() => setIsNominationIsOutOfRegLimitDialogOpen(false)}
        onConfirm={() => handleUpdate(true)}
      />
    </>
  )
}
