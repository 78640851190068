import React from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {useBucketStore} from '../utils/BucketStoreContext'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {updateCounterImbalanceForBps} from '../api/bpsApi'
import {transformToKwNumber, transformToMwNumber} from '../utils/common'
import {BpsCounterImbalance} from '../api/generated'

type EditCounterImbalanceDialogProps = {
  open: boolean
  onClose: () => void
  onUpdate: () => void
  counterImbalance: BpsCounterImbalance | undefined
}

export const EditCounterImbalanceDialog: React.FC<EditCounterImbalanceDialogProps> = ({
  open,
  onClose,
  onUpdate,
  counterImbalance,
}) => {
  const [checkBoxChecked, setCheckBoxChecked] = React.useState<boolean>(
    counterImbalance?.counter_imbalance_enabled === undefined ? false : counterImbalance.counter_imbalance_enabled,
  )
  const [rangeCIMax, setRangeCIMax] = React.useState<number | undefined>(
    transformToKwNumber(counterImbalance?.range_ci_max_mw),
  )
  const [rangeCIMin, setRangeCIMin] = React.useState<number | undefined>(
    transformToKwNumber(counterImbalance?.range_ci_min_mw),
  )

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const {run: runUpdateCounterImbalanceForBps} = useAsyncMethodWithErrorHandling(updateCounterImbalanceForBps)

  const handleConfirm = async () => {
    if (selectedBpsId) {
      await runUpdateCounterImbalanceForBps({
        updateBpsCounterImbalanceRequest: {
          bps_id: selectedBpsId,
          counter_imbalance_enabled: checkBoxChecked,
          range_ci_min_mw: parseFloat(transformToMwNumber(rangeCIMin).toFixed(3)),
          range_ci_max_mw: parseFloat(transformToMwNumber(rangeCIMax).toFixed(3)),
        },
      })
    }
    onUpdate()
  }

  const validateIfPositiveNumber = (number: string) => {
    return Number(number) >= 0
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Upraviť Proti-odchýlkovanie</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="center" justifyContent="space-between" marginY={2}>
            <Typography>Povoliť proti-odchýlkovanie</Typography>
            <Checkbox
              checked={checkBoxChecked}
              onChange={(_, checked) => {
                setCheckBoxChecked(checked)
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end">
            <Typography>Regulačný rozsah nahor od Nominácie [kW]</Typography>
            <TextField
              value={rangeCIMax}
              size={'small'}
              onChange={(e) => {
                validateIfPositiveNumber(e.target.value) ? setRangeCIMax(Number(e.target.value)) : 0
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" marginY={2}>
            <Typography>Regulačný rozsah nadol od Nominácie [kW]</Typography>
            <TextField
              value={rangeCIMin}
              size={'small'}
              onChange={(e) => {
                validateIfPositiveNumber(e.target.value) ? setRangeCIMin(Number(e.target.value)) : 0
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={handleConfirm} variant="contained">
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
