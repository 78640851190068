import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {createNominationV2, getGroupNominations, getNominationsV2} from '../api/nominationsApi'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore, useBucketStoreContext} from '../utils/BucketStoreContext'
import {ConfirmCreateNominationDialog} from './ConfirmCreateNominationDialog'
import {useNavigate} from 'react-router'
import {absolutePath} from '../navigation/utils'
import {getBpsDetailsV2, getDevicesForBps} from '../api/bpsApi'
import {BpsParametersExtended, Device, DeviceTypeEnum, NominationDevice} from '../api/generated'
import {NominationExtendedBase} from '../types/nominationExtended'
import {NominationDialogIntervalSelector} from '../components/NominationDialogIntervalSelector'
import {NominationDialogPeriods} from '../components/NominationDialogPeriods'
import {NominationDialogDevicesTable} from './NominationDialogDevicesTable'
import {NominationDialogSlider} from '../components/NominationDialogSlider'
import {NominationDialogTechParams} from '../components/NominationDialogTechParams'
import {NominationDialogStatusSelector} from './NominationDialogStatusSelector'
import {NominationIsOutOfRegLimitDialog} from './NominationIsOutOfRegLimitDialog'
import {parseToFixedNumber} from '../utils/common'

type AddNominationDialogProps = {
  open: boolean
  onClose: () => void
  baseDate: string
  isFailureTime: boolean
  selectedTab: string
}

export enum NominationInterval {
  FifteenMinutes,
  Hours,
  AllDay,
}

const getDefaultNominationExtendedData = (baseDate: string, bpsId: number): NominationExtendedBase => ({
  time_from: dayjs(baseDate).startOf('hour').toISOString(),
  time_to: dayjs(baseDate).endOf('hour').add(1, 'ms').toISOString(),
  value_percentage: undefined,
  note: '',
  bps_id: bpsId,
  p_reg_min_mw: 0,
  p_reg_max_mw: 0,
  nomination_devices: [],
  production_status: 2,
})

export const AddNominationDialog: React.FC<AddNominationDialogProps> = ({
  open,
  onClose,
  baseDate,
  isFailureTime,
  selectedTab,
}) => {
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {setData: setNominations} = useBucketStore('nominationsExtended')
  const {data: bpsNominationTableOpen, setData: setBpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')
  const {setData: setGroupNominations} = useBucketStore('groupNominations')
  const {data: productionStatus} = useBucketStore('productionStatusEnum')

  const [bpsParameters, setBpsParameters] = useState<BpsParametersExtended>()
  const [devicesForBps, setDevicesForBps] = useState<undefined | Device[]>(undefined)
  const [selectedDevices, setSelectedDevices] = useState<number[] | undefined>(undefined)
  const [enableSubmit, setEnableSubmit] = useState<boolean>(true)

  const [isConfirmCreateDialogOpen, setIsConfirmCreateDialogOpen] = useState<boolean>(false)
  const [isNominationIsOutOfRegLimitDialogOpen, setIsNominationIsOutOfRegLimitDialogOpen] = useState<boolean>(false)
  const [editedNomination, setEditedNomination] = useState<NominationExtendedBase>(() =>
    // TODO: handle undefined selectedBpsId
    getDefaultNominationExtendedData(baseDate, selectedBpsId ?? -1),
  )

  const bpsInstMw = useRef(-1)

  const [selectedInterval, setSelectedInterval] = useState(NominationInterval.Hours)

  const {run: runGetNominationsV2} = useAsyncMethodWithErrorHandling(getNominationsV2)
  const {run: runGetGroupNominations} = useAsyncMethodWithErrorHandling(getGroupNominations)
  const {run: runGetBpsDetailsV2} = useAsyncMethodWithErrorHandling(getBpsDetailsV2)
  const {run: runGetDevicesForBps} = useAsyncMethodWithErrorHandling(getDevicesForBps)

  const startOfDayTimestamp = dayjs(baseDate).startOf('day')
  const endOfDayTimestamp = dayjs(baseDate).endOf('day').add(1, 'ms')

  const navigation = useNavigate()
  const {clearAllBuckets} = useBucketStoreContext()

  const date = new Date(baseDate).toLocaleDateString('sk')

  const fetchNominations = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = baseDate.substring(0, 10)
      const result = (await runGetNominationsV2({dateFrom: normalizedDate, bpsId: selectedBpsId})).data

      setNominations(result?.nominations ?? [])
    }
  }, [selectedBpsId, baseDate, runGetNominationsV2, setNominations])

  const fetchGroupNominations = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = baseDate.substring(0, 10)
      const result = (await runGetGroupNominations({date: normalizedDate})).data

      setGroupNominations(result?.calculated ?? [])
    }
  }, [selectedBpsId, baseDate, runGetGroupNominations, setGroupNominations])

  const fetchBpsDetails = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetBpsDetailsV2({id: selectedBpsId})).data
      setBpsParameters(result?.parameters)
    }
  }, [runGetBpsDetailsV2, selectedBpsId])

  const fetchDevicesForBps = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetDevicesForBps({id: selectedBpsId})).data
      setDevicesForBps(
        result?.devices.filter((device) => device.type === DeviceTypeEnum.Chp).sort((a, b) => (a.id > b.id ? 1 : -1)),
      )
    }
  }, [runGetDevicesForBps, selectedBpsId])

  useEffect(() => {
    fetchBpsDetails()
  }, [fetchBpsDetails])

  useEffect(() => {
    fetchDevicesForBps()
  }, [fetchDevicesForBps])

  const editProperties = (changes: Partial<NominationExtendedBase>) => {
    setEditedNomination((prev) => ({...prev, ...changes}))
  }

  useEffect(() => {
    if (open) {
      // TODO: handle undefined selectedBpsId
      setEditedNomination(getDefaultNominationExtendedData(baseDate, selectedBpsId ?? -1))
      setSelectedInterval(NominationInterval.Hours)
    }
  }, [baseDate, open, selectedBpsId])

  const getSelectedDevicesInstMw = useCallback(
    (selectedDevicesToUse: number[]) =>
      devicesForBps
        ?.filter((device) => selectedDevicesToUse?.find((item) => item === device.id))
        .reduce(function (prev, current) {
          return prev + current.p_inst_mw
        }, 0),
    [devicesForBps],
  )

  const setPRegValues = useCallback(() => {
    if (!devicesForBps || !selectedDevices) {
      return
    }

    const min = devicesForBps
      ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
      .reduce(function (prev, current) {
        return prev + current?.p_reg_min_mw
      }, 0)

    const max = devicesForBps
      ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
      .reduce(function (prev, current) {
        return prev + current?.p_reg_max_mw
      }, 0)

    editProperties({
      p_reg_min_mw: min,
      p_reg_max_mw: max,
    })
  }, [devicesForBps, selectedDevices])

  const createEmptyNominationDevice = (nominationId: number): NominationDevice => {
    return {
      device_id: nominationId.toString(),
      time_from: '', // will be set on BE
      time_to: '', // will be set on BE
      p_term_mw: 0,
      p_reg_min_mw: 0,
      p_reg_max_mw: 0,
      in_nomination: false,
    }
  }

  const calculatePTermMw = (deviceCharPInstMw: number) => {
    const sumPInstMwSelectedDevices = selectedDevices ? getSelectedDevicesInstMw(selectedDevices) ?? 0 : 0

    const firstPart = sumPInstMwSelectedDevices - ((editedNomination.value_percentage ?? 0) * bpsInstMw.current) / 100
    const secondPart = deviceCharPInstMw / sumPInstMwSelectedDevices

    return (deviceCharPInstMw - firstPart * secondPart).toFixed(3)
  }

  const createNominationDevice = (
    nominationId: number,
    pRegMinMw: number,
    pRegMaxMw: number,
    pInstMw: number,
  ): NominationDevice => ({
    device_id: nominationId.toString(),
    time_from: '',
    time_to: '',
    p_term_mw: Number(calculatePTermMw(pInstMw)),
    p_reg_min_mw: pRegMinMw,
    p_reg_max_mw: pRegMaxMw,
    in_nomination: true,
  })

  const getNominationsForDevices = (): NominationDevice[] | [] => {
    const nominationsDevices: NominationDevice[] = []

    devicesForBps?.forEach((device) => {
      const newNomination =
        selectedDevices?.indexOf(device.id) !== -1
          ? createNominationDevice(device.id, device.p_reg_min_mw, device.p_reg_max_mw, device.p_inst_mw)
          : createEmptyNominationDevice(device.id)

      nominationsDevices.push(newNomination)
    })

    return nominationsDevices
  }

  const handleCreate = async (isOutOfRegLimit: boolean) => {
    try {
      if (isOutOfRegLimit) {
        editedNomination.production_status = 3
      }

      editedNomination.nomination_devices = getNominationsForDevices()

      await createNominationV2(editedNomination)

      // re-fetch
      if (selectedBpsId) {
        if (selectedTab == 'graph-view') {
          fetchNominations()
          fetchGroupNominations()
        } else if (bpsNominationTableOpen) {
          // no re-fetch when selectedTab is table-view and bpsNominationTableOpen is not open (it will be fetched in NominationsTable)
          fetchNominations()
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      // eslint-disable-next-line
      if ((error as any)?.response?.status == 401) {
        sessionStorage.clear()
        clearAllBuckets()
        navigation(absolutePath('login'))
      }
    }

    // bpsNominationTableOpen will not open in the background in when in graph-view
    if (selectedTab == 'table-view') {
      setBpsNominationTableOpen(true)
    }

    setIsConfirmCreateDialogOpen(false)
    onClose()
  }

  const handleConfirmCreate = async () => {
    const nomValue = Number(
      (editedNomination.value_percentage && bpsParameters
        ? bpsInstMw.current * (editedNomination.value_percentage / 100) - bpsParameters.tvs_mw
        : 0
      ).toFixed(3),
    )

    const maxRegValue =
      devicesForBps
        ?.filter((device) => selectedDevices?.includes(device.id))
        ?.reduce(function (prev, current) {
          return prev + current?.p_reg_max_mw
        }, 0) ?? 0

    const minRegValue =
      devicesForBps
        ?.filter((device) => selectedDevices?.includes(device.id))
        ?.reduce(function (prev, current) {
          return prev + current?.p_reg_min_mw
        }, 0) ?? 0

    if (
      (parseToFixedNumber(nomValue + (bpsParameters?.tvs_mw ?? 0), 3) > maxRegValue ||
        parseToFixedNumber(nomValue + (bpsParameters?.tvs_mw ?? 0), 3) < minRegValue) &&
      editedNomination.production_status != 3
    ) {
      setIsNominationIsOutOfRegLimitDialogOpen(true)
      return
    }

    handleCreate(false)
  }

  const handleAllDaySelected = () => {
    setSelectedInterval(NominationInterval.AllDay)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const handleHoursIntervalSelected = () => {
    setSelectedInterval(NominationInterval.Hours)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const handleFifteenMinutesIntervalSelected = () => {
    setSelectedInterval(NominationInterval.FifteenMinutes)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const updateSliderValue = useCallback(
    (selectedDevicesNewValue: number[]) => {
      const selectedDevicesInstMw = getSelectedDevicesInstMw(selectedDevicesNewValue)

      if (selectedDevicesInstMw !== undefined && bpsInstMw.current !== -1) {
        const newValue = (selectedDevicesInstMw / bpsInstMw.current) * 100
        editProperties({value_percentage: Number(newValue.toFixed(0))})
      }
    },
    [getSelectedDevicesInstMw],
  )

  // set initial selected Kjgs
  useEffect(() => {
    if (devicesForBps?.length && selectedDevices === undefined) {
      const newValue = devicesForBps.filter((item) => item.in_service).map((item) => item.id)
      setSelectedDevices(newValue)

      bpsInstMw.current =
        devicesForBps
          ?.filter((item) => item.in_service)
          .reduce(function (prev, current) {
            return prev + current.p_inst_mw
          }, 0) ?? 0

      setPRegValues()

      updateSliderValue(newValue)
    } else {
      setPRegValues()
    }
  }, [devicesForBps, selectedDevices, setPRegValues, updateSliderValue])

  const handleClickCheckBox = (id: number | undefined, checked: boolean) => {
    if (id === undefined) {
      return
    }

    if (checked) {
      // add to array of selected
      const filtered = selectedDevices?.filter((item) => item !== id)
      const updated = filtered ?? []
      updated.push(id)
      setSelectedDevices(updated)
      updateSliderValue(updated)
    } else {
      // remove from array of selected
      const filtered = selectedDevices?.filter((item) => item !== id)
      setSelectedDevices(filtered)
      filtered ? updateSliderValue(filtered) : updateSliderValue([])
    }
  }

  const isSelected = (id: number | undefined) => {
    return selectedDevices?.find((item) => item === id) !== undefined
  }

  const valueIsValid = () => {
    return editedNomination.value_percentage != undefined && editedNomination.value_percentage >= 0
  }

  const calculateMaxValueForSlider = () => {
    const maxDosForSelectedDevices = devicesForBps
      ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
      .reduce(function (prev, current) {
        return prev + current?.p_dos_max_mw
      }, 0)

    const maxDosForAllDevices = devicesForBps?.reduce(function (prev, current) {
      return prev + current?.p_dos_max_mw
    }, 0)

    return Math.floor(
      maxDosForSelectedDevices && maxDosForAllDevices ? (maxDosForSelectedDevices * 100) / maxDosForAllDevices : 0,
    )
  }

  const calculateMinValueForSlider = () => {
    const minDosForSelectedDevices = devicesForBps
      ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
      .reduce(function (prev, current) {
        return prev + current?.p_dos_min_mw
      }, 0)

    const maxDosForAllDevices = devicesForBps?.reduce(function (prev, current) {
      return prev + current?.p_dos_max_mw
    }, 0)

    return Math.floor(
      minDosForSelectedDevices && maxDosForAllDevices ? (minDosForSelectedDevices * 100) / maxDosForAllDevices : 0,
    )
  }

  const handleValueInSliderChange = (valid: boolean) => {
    setEnableSubmit(valid)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          {isFailureTime ? 'Porucha:' : 'Nová nominácia:'} {date}
        </DialogTitle>
        <DialogContent>
          <Stack>
            {isFailureTime ? (
              <Alert severity="error" sx={{marginBottom: '8px'}}>
                Zadávate poruchu
              </Alert>
            ) : (
              <NominationDialogIntervalSelector
                handleAllDaySelected={handleAllDaySelected}
                handleFifteenMinutesIntervalSelected={handleFifteenMinutesIntervalSelected}
                handleHoursIntervalSelected={handleHoursIntervalSelected}
                selectedInterval={selectedInterval}
              />
            )}

            <NominationDialogPeriods
              editedNomination={editedNomination}
              onEditProperties={editProperties}
              selectedInterval={selectedInterval}
              isProfileEdit={false}
            />

            <NominationDialogStatusSelector
              editedNomination={editedNomination}
              productionStatusEnum={productionStatus}
              onValueChange={(value: number) => editProperties({production_status: value})}
            />

            <NominationDialogDevicesTable
              handleClickCheckBox={handleClickCheckBox}
              isSelected={isSelected}
              devicesForBps={devicesForBps}
            />

            <NominationDialogSlider
              editedNomination={editedNomination}
              minValueForSlider={calculateMinValueForSlider()}
              maxValueForSlider={calculateMaxValueForSlider()}
              onEditProperties={editProperties}
              onValueChanged={handleValueInSliderChange}
            />

            <NominationDialogTechParams
              bpsInstMw={bpsInstMw.current}
              editedNomination={editedNomination}
              onEditProperties={editProperties}
              tvsMw={bpsParameters?.tvs_mw}
              ovsMw={bpsParameters?.ovs_mw}
              devicesForBps={devicesForBps ?? []}
              selectedDevices={selectedDevices ?? []}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button
            onClick={() => setIsConfirmCreateDialogOpen(true)}
            variant="contained"
            disabled={!valueIsValid() || !enableSubmit}
            color={isFailureTime ? 'error' : 'primary'}
          >
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmCreateNominationDialog
        open={isConfirmCreateDialogOpen}
        onClose={() => setIsConfirmCreateDialogOpen(false)}
        nomination={editedNomination}
        bpsNominationValue={
          editedNomination.value_percentage && bpsParameters
            ? bpsInstMw.current * (editedNomination.value_percentage / 100) - bpsParameters.tvs_mw
            : 0
        }
        onConfirm={handleConfirmCreate}
        isFailureTime={isFailureTime}
      />

      <NominationIsOutOfRegLimitDialog
        open={isNominationIsOutOfRegLimitDialogOpen}
        onClose={() => setIsNominationIsOutOfRegLimitDialogOpen(false)}
        onConfirm={() => handleCreate(true)}
      />
    </>
  )
}
