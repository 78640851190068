import React, {useEffect} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {transformToKwNumber, transformToMwNumber} from '../utils/common'
import {NominationOverride, UpdateNominationOverrideRequest} from '../api/generated'
import {getAllNominationOverride, updateAllNominationOverride} from '../api/nominationsApi'
import {useBucketStore} from '../utils/BucketStoreContext'

type NominationOverrideDialogProps = {
  open: boolean
  onClose: () => void
}

const StyledTableHeadRowForCounterImbalanceTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

export const NominationOverrideDialog: React.FC<NominationOverrideDialogProps> = ({open, onClose}) => {
  const {data: userInfo} = useBucketStore('userInfo')

  const [nominationsOverride, setNominationsOverride] = React.useState<NominationOverride[]>([])
  const [confirmDisabled, setConfirmDisabled] = React.useState<boolean>(false)

  const {run: runUpdateAllNominationOverride} = useAsyncMethodWithErrorHandling(updateAllNominationOverride)
  const {run: runGetAllNominationOverride} = useAsyncMethodWithErrorHandling(getAllNominationOverride)

  const fetchAllNominationOverride = React.useCallback(async () => {
    const result = (await runGetAllNominationOverride()).data
    setNominationsOverride(
      result?.nominations_override
        .map((item) => {
          item.value_ab1_mw = transformToKwNumber(item.value_ab1_mw)
          return item
        })
        .map((item) => {
          item.value_rbo_mw = transformToKwNumber(item.value_rbo_mw)
          return item
        }) ?? [],
    )
  }, [runGetAllNominationOverride])

  const handleConfirm = async () => {
    setConfirmDisabled(true)

    await runUpdateAllNominationOverride({
      updateAllNominationOverrideRequest: {
        update_all_nomination_override: nominationsOverride.map((item) => {
          const request: UpdateNominationOverrideRequest = {
            bps_id: item.bps.id ?? 0,
            override_enabled: item.override_enabled,
            value_ab1_mw: transformToMwNumber(item.value_ab1_mw),
            value_rbo_mw: transformToMwNumber(item.value_rbo_mw),
          }
          return request
        }),
      },
    })

    fetchAllNominationOverride()
    setConfirmDisabled(false)
  }

  const validateIfPositiveNumber = (number: string) => {
    return Number(number) >= 0
  }

  const updateNominationOverride = (nominationOverrideEdited: NominationOverride) => {
    nominationsOverride[nominationsOverride.findIndex((item) => item.bps.id == nominationOverrideEdited.bps.id)] =
      nominationOverrideEdited
    setNominationsOverride([...nominationsOverride])
  }

  useEffect(() => {
    if (userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('GROUP_OPERATOR')) {
      fetchAllNominationOverride()
    }
  }, [fetchAllNominationOverride, userInfo?.roles])

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{padding: '0px 0'}}>
              <TableHead>
                <StyledTableHeadRowForCounterImbalanceTable>
                  <TableCell>Prepísať nominácie</TableCell>
                  <TableCell />
                  <TableCell />
                </StyledTableHeadRowForCounterImbalanceTable>
              </TableHead>

              <TableBody>
                <>
                  <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableCell>BPS</TableCell>
                            <TableCell>{`AB1`}</TableCell>
                            <TableCell>{`RBO`}</TableCell>
                            <TableCell>Povoliť prepisovanie</TableCell>
                          </TableHead>
                          <TableBody>
                            {nominationsOverride
                              .sort((item1, item2) => (item1?.bps?.id ?? 0) - (item2?.bps?.id ?? 0))
                              .map((item) => (
                                <>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant={'body2'}>{item?.bps?.name}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        value={item?.value_ab1_mw}
                                        size={'small'}
                                        label={`Akt: ${transformToKwNumber(item?.current_nomination)}`}
                                        onChange={(e) => {
                                          validateIfPositiveNumber(e.target.value)
                                            ? (item.value_ab1_mw = Number(e.target.value))
                                            : (item.value_ab1_mw = 0)
                                          updateNominationOverride(item)
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        value={item?.value_rbo_mw}
                                        size={'small'}
                                        label={`Akt: ${transformToKwNumber(item?.sent_nomination)}`}
                                        onChange={(e) => {
                                          validateIfPositiveNumber(e.target.value)
                                            ? (item.value_rbo_mw = Number(e.target.value))
                                            : (item.value_ab1_mw = 0)
                                          updateNominationOverride(item)
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Switch
                                        checked={item.override_enabled}
                                        onChange={(_, checked) => {
                                          item.override_enabled = checked
                                          updateNominationOverride(item)
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zavrieť
          </Button>
          <Button onClick={handleConfirm} disabled={confirmDisabled} variant="contained">
            Použiť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
